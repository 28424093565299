import React, { ComponentType } from "react";

import ReactDOM from "react-dom/client";

const ATLAS_ID = "atlas-id";

let App: ComponentType;
if (import.meta.env.VITE_APP_BUILD_TARGET === ATLAS_ID) {
  App = React.lazy(() =>
    import("./clients/UserPortal/App").then((module) => ({
      default: module.App,
    })),
  );
} else {
  App = React.lazy(() =>
    import("./clients/StoKioskLegacy/App").then((module) => ({
      default: module.App,
    })),
  );
}

export const Root = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);
